import { ContentModel } from '../../../../domain/models'
import { contentToView } from '../../../../data/usecases/contents-to-view/content-to-view'
import { api } from '../../../../infra/http/remote'
import { AxiosResponse } from 'axios'

type FilterContentsProps ={
  publishAtWeekDay?: number
  'folder.slug'?: string
  'slug'?: string
}

export const makeContents = async (
  params: FilterContentsProps
): Promise<ContentModel[]> => {
  try {
    const response: AxiosResponse = await api.get('/contents', {
      params: {
        ...params
      }
    })

    console.log({
      params: {
        ...params
      }
    })
    console.log(response.data)

    return contentToView.build(response.data['hydra:member'])
  } catch (error) {
    return []
  }
}
