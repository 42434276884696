import React from 'react'
import { Box, Flex, Grid, Image } from '@chakra-ui/core'
import { FolderContentModel } from '../../domain/models'
import Container from '../ui/foundation/container/container'
import { Typography } from '../ui/foundation'
import { CardFolderContentItem } from '../ui/layout'
import FavoriteEvent from '../favorites/favorite-event'

interface FolderContentProps {
  item: FolderContentModel,
  view?: boolean
}

const FolderContent = ({ item, view }: FolderContentProps): JSX.Element => {
  return (
    <Box className={item.folder.slug}>
      <Container>
        {item.folder.logo ? (
          <Image
            objectFit="cover"
            src={item.folder.logo}
            alt={item.folder.title}
            ignoreFallback
            mx="auto"
            mb={8}
          />
        ) : (
          <Typography type="4xl" as="h2" textAlign="center">{item.folder.title.toUpperCase()}</Typography>
        )}
        <Flex justifyContent="center">
          <Typography type="md" as="p" mr="10px">Adicionar Treino aos Favoritos</Typography>
          <FavoriteEvent folderIri={item.folder['@id']} favorite={item.folder.currentUserCollection.favorite} />
        </Flex>
        <Grid templateColumns={['repeat(2, 1fr)', '', 'repeat(5, 1fr)']} gap={5} my={10}>
          {item.contents.map((content, index) => (
            <CardFolderContentItem key={content['@id']} content={content} view={view} view_id={index+1} trainingSlug={item.folder.slug} />
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default FolderContent
